import { useState, useEffect } from 'react';
import { Stack } from '@carbon/react';
import { Link } from 'react-router-dom';
import { TrashCan } from '@carbon/icons-react';
import CRUDTable from '../../components/CRUDTable';
import http from '../../httpCommon';
import CustomButton from '../../components/CustomButton';

export default function CRUDScreen({ title, endpoint, basePath, headers, showRowDataExtra }) {
  const [transactions, setTransactions] = useState([]);

  const fetchData = () => {
    http.get(endpoint).then((response) => {
      setTransactions(response.data);
    });
  };
  useEffect(fetchData, [endpoint]);

  const deleteItem = (id, setLoading) => {
    http
      .delete(`${endpoint}/${id}`)
      .then((response) => {
        setLoading('finished', 'Deleted');
        fetchData();
      })
      .catch((error) => {
        setLoading('error', 'Something Went Wrong');
      });
  };

  const handleUploadMany = (data) => {
    http
      .post(endpoint, data)
      .then((response) => {
        fetchData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showRowData = () => {
    return transactions.map((item) => {
      const newItem = {
        ...item,
        id: item._id,
        active: item.active.toString(),
        sold: item.sold.toString(),
        actions: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link style={{ marginRight: 20 }} to={`${basePath}/edit/${item._id}`}>
              Edit
            </Link>
            <CustomButton
              type="link"
              onClick={(setLoading) => {
                setLoading('active', 'Deleting');
                deleteItem(item._id, setLoading);
              }}
              style={{ color: 'red', cursor: 'pointer' }}
              renderIcon={TrashCan}
            >
              Delete
            </CustomButton>
          </div>
        ),
      };
      return showRowDataExtra ? showRowDataExtra(newItem) : newItem;
    });
  };

  return (
    <article>
      <Stack className="margin-bottom-5">
        <h1>{title} Inventory</h1>
      </Stack>

      <CRUDTable
        headerData={headers}
        rowData={showRowData(transactions)}
        deleteItem={deleteItem}
        handleUploadMany={handleUploadMany}
        basePath={basePath}
      />
    </article>
  );
}
