import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Link,
  DatePicker,
  DatePickerInput,
  Stack,
} from '@carbon/react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

export default function OrderTable({ headerData, rowData }) {
  const navigate = useNavigate();

  const showRowData = () => {
    return rowData.map((item) => {
      return {
        id: item._id,
        date: dayjs(item.date).format('DD/MM/YYYY'),
        order: item.apiOrderID,
        customer: item.purchaseEmail,
        total: (item.price / 100).toFixed(2),
        view: (
          <Link className="link" onClick={() => navigate(`/orders/${item._id}`)}>
            View
          </Link>
        ),
      };
    });
  };

  // TODO: When date range has 2 dates, filter the table data
  const onChangeDate = (e) => {
    console.log(e);
  };

  return (
    <>
      <Stack className="margin-bottom-5">
        <DatePicker dateFormat="m/d/Y" datePickerType="range" onChange={onChangeDate}>
          <DatePickerInput id="date-picker-range-start" placeholder="mm/dd/yyyy" labelText="Start Date" type="text" />
          <DatePickerInput id="date-picker-range-end" placeholder="mm/dd/yyyy" labelText="End Date" type="text" />
        </DatePicker>
      </Stack>

      <DataTable rows={showRowData()} headers={headerData}>
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <TableContainer>
            <Table {...getTableProps()} size="xl" useZebraStyles={false}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
    </>
  );
}
