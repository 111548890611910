import { Button, InlineLoading, Link } from '@carbon/react';
import { useEffect, useState } from 'react';

export default function CustomButton(props) {
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(props.loadDescription || 'Submitting...');

  const setIsLoading = (status, newDescription) => {
    setLoading(status);
    setDescription(newDescription || description);
  };

  useEffect(() => {
    if (['finished', 'error'].includes(loading))
      setTimeout(() => {
        setLoading(false);
        setDescription(props.loadDescription || 'Submitting...');
      }, 1500);
  }, [loading, props.loadDescription]);

  useEffect(() => {
    setLoading(props.loading || false);
  }, [props.loading]);

  useEffect(() => {
    setDescription(props.loadDescription || 'Submitting...');
  }, [props.loadDescription]);

  return (
    <>
      {loading ? (
        <InlineLoading description={description} status={loading} />
      ) : props.type === 'link' ? (
        <Link {...props} onClick={(event) => props.onClick && props.onClick(setIsLoading, event)}>
          {props.children}
        </Link>
      ) : (
        <Button {...props} onClick={(event) => props.onClick && props.onClick(setIsLoading, event)}>
          {props.children}
        </Button>
      )}
    </>
  );
}
