import { Outlet, NavLink } from 'react-router-dom';
import {
  Header,
  HeaderContainer,
  SkipToContent,
  HeaderName,
  HeaderMenuButton,
  SideNav,
  SideNavItems,
  Content,
  SideNavMenu,
} from '@carbon/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

export default function MainLayout() {
  const [isSideNavExpanded, setIsSideNavExpanded] = useState(false);
  const auth = useSelector((state) => state.auth);

  console.log('Layout auth', auth);

  const renderContent = () => {
    switch (auth) {
      // Show spinner while user is being determined
      case null:
        return <>Loading...</>;

      // If empty user, redirect to login
      case '': {
        window.location.href = `/login`;
        return null;
      }

      // If user exists, display layout with current page
      default:
        return (
          <HeaderContainer
            render={() => (
              <>
                <Header aria-label="Dashboard">
                  <SkipToContent />
                  <HeaderMenuButton
                    aria-label="Open menu"
                    onClick={() => setIsSideNavExpanded(!isSideNavExpanded)}
                    isActive={isSideNavExpanded}
                  />
                  <HeaderName href="#" prefix="Dashboard">
                    GooseSmurfs
                  </HeaderName>

                  <SideNav aria-label="Side navigation" expanded={isSideNavExpanded}>
                    <SideNavItems>
                      {auth.email == 'nikolasnahomu@gmail.com' || auth.email == 'jaspernasr@gmail.com' ? (
                        <></>
                      ) : (
                        <>
                          <NavLink
                            to="/"
                            className="cds--side-nav__link"
                            onClick={() => setIsSideNavExpanded(!isSideNavExpanded)}
                          >
                            Dashboard
                          </NavLink>
                          <NavLink
                            to="/orders"
                            className="cds--side-nav__link"
                            onClick={() => setIsSideNavExpanded(!isSideNavExpanded)}
                          >
                            Orders
                          </NavLink>
                        </>
                      )}
                      <SideNavMenu title="Inventory" defaultExpanded={true}>
                        <NavLink
                          to="/inventory/unranked"
                          className="cds--side-nav__link"
                          onClick={() => setIsSideNavExpanded(!isSideNavExpanded)}
                        >
                          Unranked
                        </NavLink>
                        <NavLink
                          to="/inventory/ranked"
                          className="cds--side-nav__link"
                          onClick={() => setIsSideNavExpanded(!isSideNavExpanded)}
                        >
                          Ranked
                        </NavLink>
                        <NavLink
                          to="/inventory/mmr"
                          className="cds--side-nav__link"
                          onClick={() => setIsSideNavExpanded(!isSideNavExpanded)}
                        >
                          MMR
                        </NavLink>
                      </SideNavMenu>

                      <NavLink
                        to="/login"
                        className="cds--side-nav__link"
                        onClick={() => {
                          axios.get('/auth/logout').then(() => {
                            window.location.href = '/login';
                          });
                        }}
                      >
                        Log Out
                      </NavLink>
                    </SideNavItems>
                  </SideNav>
                </Header>

                <Content id="main-content">
                  <Outlet />
                </Content>
              </>
            )}
          />
        );
    }
  };

  return <>{renderContent()}</>;
}
