import { Checkbox, Form, FormGroup, Stack, Select, SelectItem, TextInput, Loading } from '@carbon/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import http from '../../../httpCommon';
import CustomButton from '../../../components/CustomButton';

export default function AddUnrankedScreen() {
  const REGION = ['NA', 'EUW', 'EUNE', 'OCE', 'LAN', 'LAS'];
  const ESSENCE = ['50k', '60k', '70k', '100k', '150k', '200k'];
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState([false, null]);

  const [formData, setFormData] = useState({
    active: true,
    sold: false,
    region: REGION[0],
    essenceNumber: ESSENCE[0],
  });
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    http
      .get(`/unranked/${id}`, { withCredentials: true })
      .then((response) => {
        const data = (({ active, sold, region, essenceNumber, skinId, username, password }) => ({
          active,
          sold,
          region,
          essenceNumber,
          skinId,
          username,
          password,
        }))(response.data);

        setFormData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(['active', 'Submitting...']);
    const httpPromise = id
      ? http.put(`/unranked/${id}`, formData, { withCredentials: true })
      : http.post('/unranked/', formData, { withCredentials: true });

    httpPromise
      .then(() => {
        navigate('/inventory/unranked');
      })
      .catch((error) => {
        console.log(error);
        const errorMsg =
          error.response?.data?.code === 11000 ? 'Username Already Exists' : 'Something went wrong, please try again!';
        setIsSubmitting(['error', errorMsg]);
      });
  };
  const handleChange = (event) => {
    let newData = formData;
    let value = event.target.value;
    if (event.target.type === 'checkbox') value = !formData[event.target.name];
    newData[event.target.name] = value;
    setFormData(newData);
  };

  if (loading) return <Loading />;
  return (
    <>
      <Stack className="margin-bottom-5">
        <h1>{id ? 'Edit' : 'Add'} Unranked</h1>
      </Stack>
      <Form onSubmit={handleSubmit}>
        <Stack gap={7}>
          <FormGroup>
            <Checkbox
              value={formData['active']}
              name="active"
              id="active"
              labelText="Active"
              defaultChecked={formData['active']}
              onChange={handleChange}
            />
            <Checkbox
              name="sold"
              id="sold"
              labelText="Sold"
              defaultChecked={formData['sold']}
              onChange={handleChange}
            />
          </FormGroup>
          <Select
            required={true}
            defaultValue={formData['region']}
            name="region"
            labelText="Region"
            onChange={handleChange}
          >
            {REGION.map((value) => (
              <SelectItem value={value} text={value} />
            ))}
          </Select>

          <Select
            required={true}
            defaultValue={formData['essenceNumber']}
            name="essenceNumber"
            labelText="Number of Blue Essence"
            onChange={handleChange}
          >
            {ESSENCE.map((value) => (
              <SelectItem value={value} text={value} />
            ))}
          </Select>
          <TextInput defaultValue={formData['skinId']} name="skinId" labelText="Skin ID" onChange={handleChange} />
          <TextInput
            defaultValue={formData['username']}
            required={true}
            name="username"
            labelText="Username"
            onChange={handleChange}
          />
          <TextInput
            defaultValue={formData['password']}
            required={true}
            name="password"
            labelText="Password"
            onChange={handleChange}
          />
          <CustomButton type="submit" loading={isSubmitting[0]} loadDescription={isSubmitting[1]}>
            Submit
          </CustomButton>
        </Stack>
      </Form>
    </>
  );
}
