import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  Button,
  TableToolbarSearch,
} from '@carbon/react';
import { Link } from 'react-router-dom';
import { Add, Upload } from '@carbon/icons-react';
import Papa from 'papaparse';

export default function CRUDTable({ headerData, rowData, handleUploadMany, basePath }) {
  const handleFileUpload = (event) => {
    console.log(event, event.target.files);
    Papa.parse(event.target.files[0], {
      complete: (results) => {
        event.target.value = '';
        console.log(results, results.data);
        handleUploadMany(results.data);
      },
      header: true,
    });
  };
  return (
    <>
      <DataTable rows={rowData} headers={headerData} isSortable={true}>
        {({ rows, headers, getHeaderProps, getRowProps, getTableProps, getToolbarProps, onInputChange }) => (
          <TableContainer>
            <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
              <TableToolbarContent>
                <TableToolbarSearch onChange={onInputChange} />
                <Link to={`${basePath}/add`}>
                  <Button renderIcon={Add}>Add New</Button>
                </Link>
                <Button onClick={(event) => event.target.childNodes[0]?.click()} renderIcon={Upload} kind="secondary">
                  <input type="file" hidden="true" onChange={handleFileUpload} />
                  Import
                </Button>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()} size="xl" useZebraStyles={false}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
    </>
  );
}
