import React, { useState } from 'react';
import { Button, Form, FormGroup, TextInput } from '@carbon/react';
import axios from 'axios';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Email:', email);
    console.log('Password:', password);

    axios
      .post(
        '/auth/login',
        {
          email,
          password,
        },
        { withCredentials: true },
      )
      .then((response) => {
        // Enter login authentication flow

        if (response.status === 200) {
          window.location.replace('/');
        }
      })
      .catch((error) => {
        console.log('login error: ', error);
        this.setState({
          loginError: 'There was an error logging in. Please enter the correct email and password.',
        });
      });
  };

  return (
    <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '2rem' }}>Login</h1>
      <Form onSubmit={handleSubmit}>
        <FormGroup legendText="">
          <TextInput
            id="email"
            labelText="Email"
            placeholder="Enter your email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </FormGroup>
        <FormGroup legendText="">
          <TextInput.PasswordInput
            id="password"
            labelText="Password"
            placeholder="Enter your password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </FormGroup>
        <Button style={{ width: '100%' }} type="submit">
          Login
        </Button>
      </Form>
    </div>
  );
};

export default LoginPage;
