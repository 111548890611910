import CRUDScreen from '../CRUDScreen';

export default function UnrankedScreen() {
  const headers = [
    {
      header: 'Region',
      key: 'region',
    },
    {
      header: 'Number of Blue Essence',
      key: 'essenceNumber',
    },
    {
      header: 'Skin ID',
      key: 'skinId',
    },
    {
      header: 'Username',
      key: 'username',
    },
    {
      header: 'Sold',
      key: 'sold',
    },
    {
      header: 'Active',
      key: 'active',
    },
    {
      header: 'Actions',
      key: 'actions',
    },
  ];

  return <CRUDScreen title="Unranked" endpoint="/unranked" basePath="/inventory/unranked" headers={headers} />;
}
