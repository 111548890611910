import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import MainLayout from './layout/MainLayout';
import DetailedOrder from './screens/DetailedOrder';
import HomeScreen from './screens/HomeScreen';
import OrdersScreen from './screens/OrdersScreen';
import LoginScreen from './screens/LoginScreen';
import * as actions from './actions';
import UnrankedScreen from './screens/Inventory/Unranked/MainScreen';
import AddUnrankedScreen from './screens/Inventory/Unranked/AddScreen';
import RankedScreen from './screens/Inventory/Ranked/MainScreen';
import AddRankedScreen from './screens/Inventory/Ranked/AddScreen';
import MMRScreen from './screens/Inventory/MMR/MainScreen';
import AddMMRScreen from './screens/Inventory/MMR/AddScreen';

class App extends Component {
  componentDidMount() {
    this.props.fetchUser(); // fetches the current user
  }

  render() {
    return (
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" exact element={<HomeScreen />} />
          <Route path="/orders" exact element={<OrdersScreen />} />
          <Route path="/orders/:id" exact element={<DetailedOrder />} />

          <Route path="/inventory/unranked" exact element={<UnrankedScreen />} />
          <Route path="/inventory/unranked/add" exact element={<AddUnrankedScreen />} />
          <Route path="/inventory/unranked/edit/:id" exact element={<AddUnrankedScreen />} />

          <Route path="/inventory/ranked" exact element={<RankedScreen />} />
          <Route path="/inventory/ranked/add" exact element={<AddRankedScreen />} />
          <Route path="/inventory/ranked/edit/:id" exact element={<AddRankedScreen />} />

          <Route path="/inventory/mmr" exact element={<MMRScreen />} />
          <Route path="/inventory/mmr/add" exact element={<AddMMRScreen />} />
          <Route path="/inventory/mmr/edit/:id" exact element={<AddMMRScreen />} />
        </Route>
        <Route path="/login" exact element={<LoginScreen />} />
      </Routes>
    );
  }
}

export default connect(null, actions)(App);
