import axios from 'axios';
import { FETCH_USER, LOGOUT_USER, LOGIN_USER } from './types';

// Fetch the current logged in user (if any)
export const fetchUser = () => async (dispatch) => {
  const res = await axios.get('/auth/user');
  dispatch({ type: FETCH_USER, payload: res.data });
};

// Log the user in
export const loginUser = () => async (dispatch) => {
  const res = await axios.post('/auth/login');
  dispatch({ type: LOGIN_USER, payload: res.data });
};

// Log the user out
export const logoutUser = () => async (dispatch) => {
  const res = await axios.get('/auth/logout').then((res) => (window.location.href = '/'));
  dispatch({ type: LOGOUT_USER, payload: res.data });
};
