export const HEADER_DATA = [
  {
    header: 'Date',
    key: 'date',
  },
  {
    header: 'Order ID',
    key: 'order',
  },
  {
    header: 'Customer',
    key: 'customer',
  },
  {
    header: 'Total',
    key: 'total',
  },
  {
    header: '',
    key: 'view',
  },
];

export const ROW_DATA = [
  {
    id: 1,
    date: 'Fri Mar 31 2023 ',
    order: '47232',
    customer: 'Matthew Littel',
    total: '761.00',
  },
  {
    id: 2,
    date: 'Fri Mar 31 2023 ',
    order: '87468',
    customer: 'Max Nader',
    total: '526.00',
  },
  {
    id: 3,
    date: 'Fri Mar 31 2023 ',
    order: '232323',
    customer: 'Olga Hilll V',
    total: '261.00',
  },
  {
    id: 4,
    date: 'Fri Mar 31 2023 ',
    order: '32532',
    customer: 'Josh Hermiston',
    total: '522.00',
  },
  {
    id: 5,
    date: 'Fri Mar 31 2023 ',
    order: '47232',
    customer: 'Matthew Littel',
    total: '321.00',
  },
];
