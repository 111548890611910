import { Checkbox, Form, FormGroup, Stack, Select, SelectItem, TextInput, Loading } from '@carbon/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import http from '../../../httpCommon';
import CustomButton from '../../../components/CustomButton';

const RANK = ['Iron', 'Bronze', 'Silver', 'Gold', 'Platinum', 'Emerald', 'Diamond'];
const REGION = ['NA', 'EUW', 'EUNE', 'OCE', 'LAN', 'LAS'];
const ACCOUNT_TYPE = ['Handleveled', 'Botted'];
const DIVISION = ['I', 'II', 'III', 'IV'];

export default function AddRankedScreen() {
  const [isSubmitting, setIsSubmitting] = useState([false, null]);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    active: true,
    sold: false,
    region: REGION[0],
    accountType: ACCOUNT_TYPE[0],
    division: DIVISION[0],
    rank: RANK[0],
  });

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);

    http
      .get(`/ranked/${id}`)
      .then((response) => {
        const data = (({
          active,
          sold,
          region,
          username,
          password,
          skinId,
          accountType,
          creationEmail,
          creationDate,
          birthDate,
          createdIn,
          verifiedIn,
          summoner,
          division,
          rank,
        }) => ({
          active,
          sold,
          region,
          username,
          password,
          skinId,
          accountType,
          creationEmail,
          creationDate,
          birthDate,
          createdIn,
          verifiedIn,
          summoner,
          division,
          rank,
        }))(response.data);

        setFormData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [id]);

  const handleSubmit = (event) => {
    setIsSubmitting(['active', 'Submitting...']);
    event.preventDefault();
    console.log(formData);

    const httpPromise = id
      ? http.put(`/ranked/${id}`, formData, { withCredentials: true })
      : http.post('/ranked/', formData, { withCredentials: true });

    httpPromise
      .then(() => {
        navigate('/inventory/ranked');
      })
      .catch((error) => {
        console.log(error);
        const errorMsg =
          error.response?.data?.code === 11000 ? 'Username Already Exists' : 'Something went wrong, please try again!';
        setIsSubmitting(['error', errorMsg]);
      });
  };

  const handleChange = (event) => {
    let newData = formData;
    let value = event.target.value;
    if (event.target.type === 'checkbox') value = !formData[event.target.name];
    newData[event.target.name] = value;
    setFormData(newData);
  };

  if (loading) return <Loading />;

  return (
    <>
      <Stack className="margin-bottom-5">
        <h1>{id ? 'Edit' : 'Add'} Ranked</h1>
      </Stack>

      <Form onSubmit={handleSubmit}>
        <Stack gap={7}>
          <FormGroup>
            <Checkbox
              value={formData['active']}
              name="active"
              id="active"
              labelText="Active"
              defaultChecked={formData['active']}
              onChange={handleChange}
            />

            <Checkbox
              name="sold"
              id="sold"
              labelText="Sold"
              defaultChecked={formData['sold']}
              onChange={handleChange}
            />
          </FormGroup>
          <Select required={true} defaultValue={formData['rank']} name="rank" labelText="Rank" onChange={handleChange}>
            {RANK.map((value) => (
              <SelectItem value={value} text={value} />
            ))}
          </Select>
          <Select
            required={true}
            defaultValue={formData['division']}
            name="division"
            labelText="Division"
            onChange={handleChange}
          >
            {DIVISION.map((value) => (
              <SelectItem value={value} text={value} />
            ))}
          </Select>
          <Select
            required={true}
            defaultValue={formData['region']}
            name="region"
            labelText="Region"
            onChange={handleChange}
          >
            {REGION.map((value) => (
              <SelectItem value={value} text={value} />
            ))}
          </Select>
          <Select
            required={true}
            defaultValue={formData['accountType']}
            name="accountType"
            labelText="Account Type"
            onChange={handleChange}
          >
            {ACCOUNT_TYPE.map((value) => (
              <SelectItem value={value} text={value} />
            ))}
          </Select>
          <TextInput
            defaultValue={formData['username']}
            required={true}
            name="username"
            labelText="Username"
            onChange={handleChange}
          />
          <TextInput
            defaultValue={formData['password']}
            required={true}
            name="password"
            labelText="Password"
            onChange={handleChange}
          />
          <TextInput defaultValue={formData['skinId']} name="skinId" labelText="Skin ID" onChange={handleChange} />
          <TextInput
            defaultValue={formData['createdIn']}
            name="createdIn"
            labelText="Created In"
            onChange={handleChange}
          />
          <TextInput
            defaultValue={formData['birthDate']}
            name="birthDate"
            labelText="Birth Date"
            onChange={handleChange}
          />
          <TextInput
            defaultValue={formData['verifiedIn']}
            name="verifiedIn"
            labelText="Verified In"
            onChange={handleChange}
          />
          <TextInput
            defaultValue={formData['creationEmail']}
            name="creationEmail"
            labelText="Creation Email"
            onChange={handleChange}
          />
          <TextInput
            defaultValue={formData['creationDate']}
            name="creationDate"
            labelText="Creation Date"
            onChange={handleChange}
          />
          <TextInput defaultValue={formData['summoner']} name="summoner" labelText="Summoner" onChange={handleChange} />
          <CustomButton type="submit" loading={isSubmitting[0]} loadDescription={isSubmitting[1]}>
            Submit
          </CustomButton>{' '}
        </Stack>
      </Form>
    </>
  );
}
