import { FETCH_USER, LOGOUT_USER, LOGIN_USER, REFECT_USER_DATA } from '../actions/types';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = null, action) {
  switch (action.type) {
    case FETCH_USER:
      return action.payload;
    case LOGIN_USER:
      return action.payload;
    case LOGOUT_USER:
      return action.payload;
    default:
      return state;
  }
}
