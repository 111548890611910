import { useEffect, useState } from 'react';
import { Button, Column, Grid, Link, Stack } from '@carbon/react';
import { ArrowLeft } from '@carbon/icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import axios from 'axios';
import dayjs from 'dayjs';

export default function DetailedOrder() {
  const [transaction, setTransaction] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    axios.get(`https://lol-accounts-api.herokuapp.com/api/transactions/${id}`).then((res) => {
      setTransaction(res.data);
    });
  }, [id]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  console.log('transaction', transaction);

  return (
    <article>
      <Stack className="margin-bottom-5">
        <h1>Orders</h1>
      </Stack>

      <Stack className="margin-bottom-7">
        <Link className="link" onClick={() => navigate('/orders')}>
          <ArrowLeft /> &nbsp; Return to All Orders
        </Link>
      </Stack>

      <Grid fullWidth style={{ padding: 0 }}>
        <Column sm={4} md={5} lg={11} style={{ margin: 0 }}>
          <Stack gap={3} className="margin-bottom-5">
            <div>
              <h3>Order #{transaction.apiOrderID}</h3>
            </div>

            <div>
              <p>
                Placed on {dayjs(transaction.date).format('ddd, MMM DD, YYYY')} at{' '}
                {dayjs(transaction.date).format('h:mm A')}
              </p>
            </div>
          </Stack>

          <table className="margin-bottom-7">
            <thead>
              <tr>
                <th className="text-align-start">
                  <p>Product</p>
                </th>
                <th>
                  <p>Price</p>
                </th>
                <th>
                  <p>QTY</p>
                </th>
                <th className="text-align-end">
                  <p>TOTAL</p>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                {transaction?.lineItems ? (
                  transaction?.lineItems[0]?.accountType === 'mmr' ? (
                    <td>
                      <p>
                        {transaction?.lineItems ? transaction?.lineItems[0]?.region : null}{' '}
                        {transaction?.lineItems ? transaction?.lineItems[0]?.mmr : null} MMR Account
                      </p>
                      <p>Skin ID: {transaction?.requestedSkin || 'none'}</p>
                      <p>Username: {transaction?.lineItems ? transaction?.lineItems[0]?.accountUsername : 'None'}</p>
                      <p>Password: {transaction?.lineItems ? transaction?.lineItems[0]?.accountPassword : 'None'}</p>
                    </td>
                  ) : transaction?.lineItems[0]?.accountType === 'ranked' ? (
                    <td>
                      <p>
                        {transaction?.lineItems ? transaction?.lineItems[0]?.region : null}{' '}
                        {transaction?.lineItems ? transaction?.lineItems[0]?.rank : null}{' '}
                        {transaction?.lineItems ? transaction?.lineItems[0]?.division : null} Ranked Account
                      </p>
                      <p>Skin ID: {transaction?.requestedSkin || 'none'}</p>
                      <p>Username: {transaction?.lineItems ? transaction?.lineItems[0]?.accountUsername : 'None'}</p>
                      <p>Password: {transaction?.lineItems ? transaction?.lineItems[0]?.accountPassword : 'None'}</p>
                    </td>
                  ) : (
                    <td>
                      <p className="font-bold">
                        {transaction?.lineItems ? transaction?.lineItems[0]?.region : null}{' '}
                        {`${transaction?.lineItems ? transaction?.lineItems[0]?.be : null} BE`} Account
                      </p>
                      <p>Skin ID: {transaction?.requestedSkin || 'none'}</p>
                      <p>Username: {transaction?.lineItems ? transaction?.lineItems[0]?.accountUsername : 'None'}</p>
                      <p>Password: {transaction?.lineItems ? transaction?.lineItems[0]?.accountPassword : 'None'}</p>
                    </td>
                  )
                ) : null}

                <td className="text-align-center">
                  <p>${(transaction.price / 100).toFixed(2)}</p>
                </td>
                <td className="text-align-center">
                  <p>1</p>
                </td>
                <td className="text-align-end">
                  <p className="font-bold">${(transaction.price / 100).toFixed(2)}</p>
                </td>
              </tr>

              {/* <tr>
                <td>
                  <p className="font-bold">NA 70k BE Unranked Account Skin: None</p>
                  <p>Skin: None</p>
                </td>
                <td className="text-align-center">
                  <p>$120</p>
                </td>
                <td className="text-align-center">
                  <p>1</p>
                </td>
                <td className="text-align-end">
                  <p className="font-bold">$120.00</p>
                </td>
              </tr> */}
            </tbody>

            <tfoot>
              <tr>
                <td>
                  <p>Subtotal</p>
                </td>
                <td></td>
                <td></td>
                <td className="text-align-end">
                  <p>${(transaction.price / 100).toFixed(2)}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Tax</p>
                </td>
                <td></td>
                <td></td>
                <td className="text-align-end">
                  <p>$0</p>
                </td>
              </tr>
              <tr>
                <td>
                  <h4 className="font-bold">Total</h4>
                </td>
                <td></td>
                <td></td>
                <td className="text-align-end">
                  <h4 className="font-bold">${(transaction.price / 100).toFixed(2)}</h4>
                </td>
              </tr>
            </tfoot>
          </table>

          <div className="margin-bottom-7">
            <Button
              kind="secondary"
              size="lg"
              onClick={() => {
                window.open(
                  `https://dashboard.stripe.com/payments/${transaction.stripePaymentIntent}`,
                  '_blank', // <- This is what makes it open in a new window.
                );
              }}
            >
              View on Stripe
            </Button>
          </div>
        </Column>

        <Column sm={4} md={1} lg={1} />

        <Column sm={4} md={1} lg={3} style={{ margin: 0 }}>
          <Stack className="margin-bottom-7">
            <h4 className="font-bold text-decoration-underline">{transaction.purchaseEmail}</h4>
          </Stack>

          {/* <Stack className="margin-bottom-7">
            <div className="margin-bottom-5">
              <h4 className="font-bold">Billing Address</h4>
            </div>

            <div style={{ color: 'rgba(82, 82, 82, 1)' }}>
              <p>Payment Status:</p>
              <p>Customer Name</p>
              <p>Street Address</p>
              <p>City, State/Province, Postal/Zip Code Country</p>
            </div>
          </Stack>

          <Stack>
            <div className="margin-bottom-5">
              <h4 className="font-bold">Shipping Address</h4>
            </div>

            <div style={{ color: 'rgba(82, 82, 82, 1)' }}>
              <p>Same as Billing</p>
            </div>
          </Stack> */}
        </Column>
      </Grid>
    </article>
  );
}
