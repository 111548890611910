import { useState, useEffect } from 'react';
import { Tabs, TabList, Tab, TabPanels, TabPanel, Stack } from '@carbon/react';
import axios from 'axios';
import { ROW_DATA, HEADER_DATA } from '../consts/DATA_TABLE';
import OrderTable from '../components/OrderTable';
import { useSelector } from 'react-redux';

export default function OrdersScreen() {
  const [transactions, setTransactions] = useState([]);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchTransactions = async () => {
      const { data } = await axios.get('https://lol-accounts-api.herokuapp.com/api/transactions');
      setTransactions(data.reverse());
    };

    fetchTransactions();
  }, []);

  console.log(transactions);

  return (
    <article>
      <Stack className="margin-bottom-5">
        <h1>Orders</h1>
      </Stack>

      <Tabs>
        <TabList aria-label="List of tabs" contained={false}>
          <Tab disabled={false}>Complete</Tab>
          {/* <Tab disabled={false}>Complete</Tab>
          <Tab disabled={false}>Pending</Tab>
          <Tab disabled={false}>Failed</Tab> */}
        </TabList>
        <TabPanels>
          <TabPanel style={{ padding: '20px 0px' }}>
            <OrderTable headerData={HEADER_DATA} rowData={transactions} />
          </TabPanel>

          {/* <TabPanel style={{ padding: '20px 0px' }}>
            <OrderTable headerData={HEADER_DATA} rowData={ROW_DATA} />
          </TabPanel>

          <TabPanel style={{ padding: '20px 0px' }}>
            <OrderTable headerData={HEADER_DATA} rowData={ROW_DATA} />
          </TabPanel>

          <TabPanel style={{ padding: '20px 0px' }}>
            <OrderTable headerData={HEADER_DATA} rowData={ROW_DATA} />
          </TabPanel> */}
        </TabPanels>
      </Tabs>
    </article>
  );
}
